export const theme = {
  colors: {
    primary: "#001a35",
    secondary: "#0057ff",
    dark: "#333333",
    light: "#ffffff",
  },
  font: {
    familyNeueMachina: "Neue Machina",
    familyInter: "Inter",
  },
};

export const mediaSizes = {
  xs: "320px",
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  xxl: "1536px",
};
export const bckgImg = {
  services_startup: require("../assets/images/background/landing/Services_&_StartUps@2x.png"),
};

export const strategyImgs = {
  str_1: require("../assets/images/Strategy/Strategy 1@2x.png"),
  str_2: require("../assets/images/Strategy/Strategy 2@2x.png"),
  str_3: require("../assets/images/Strategy/Strategy 3@2x.png"),
  str_4: require("../assets/images/Strategy/Strategy 4@2x.png"),
  str_timeline: require("../assets/images/Strategy/Strategy Timeline@2x.png"),
};

export const logos = {
  landing_logo: require("../assets/logo/Logo.png"),
  // mobile_menu_logo: require("../assets/logo/LogoMobileDropMenuVersion.svg"),
  footer_logo: require("../assets/logo/SolvenoxLogoWhiteVersion2x.png"),
};
